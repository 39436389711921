<template>
  <b-sidebar
    id="sidebar-review"
    ref="bSidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :visible="isVisible"
    @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    @hidden="clearForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Waybill Review
        </h5>

        <feather-icon
          id="hideSideBar"
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide();isVisible=false"
        />

      </div>

      <!-- Body -->
      <validation-observer ref="reviewRules">
        <b-form
          class="p-2"
          @submit.prevent
        >
          <!-- Transaction ID -->
          <b-form-group
            label="Transaction ID"
            label-for="transactionID"
          >
            <b-form-textarea
              id="transactionID"
              v-model="reviewData.trades"
              rows="6"
              trim
              wrap="hard"
              :disabled="true"
            />
          </b-form-group>

          <!-- Review Status -->
          <validation-provider
            #default="{ errors }"
            name="Review Status"
            rules="required"
          >
            <b-form-group
              label="Review Status"
              label-for="reviewStatus"
            >
              <v-select
                id="reviewStatus"
                v-model="reviewData.status"
                placeholder="select"
                :options="statusOptions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Internal Notes"
            rules="required"
          >
            <b-form-group
              label="Internal Notes"
              label-for="internalNotes"
            >
              <b-form-textarea
                id="internalNotes"
                v-model="reviewData.note"
                rows="2"
                trim
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Notice -->
          <validation-provider
            v-if="reviewData.status === '1100'"
            #default="{ errors }"
            name="Notice"
            rules="required"
          >
            <b-form-group
              label="Notice"
              label-for="notice"
            >
              <v-select
                id="notice"
                v-model="reviewData.pagsmileNotice"
                placeholder="select"
                :options="noticeOptions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <el-divider class="mt-3 mb-1" />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="reviewSubmit(hide)"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import usewaybillJs from './waybill'

const {
  reviewSubmitDetail,
} = usewaybillJs()

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormTextarea, BButton, vSelect, ValidationProvider, ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    reviewSubmit(hide) {
      this.$refs.reviewRules.validate().then(success => {
        if (success) {
          const params = {
            access_token: useJwt.getToken(),
            trades: this.reviewData.trades.replace('\n', ''),
            status: this.reviewData.status,
            note: this.reviewData.note,
            pagsmileNotice: this.reviewData.pagsmileNotice,
          }
          const that = this
          reviewSubmitDetail(
            params,
            () => {
              hide()
              that.isVisible = false
              that.$emit('clearSidebarToTable', '')
            },
            () => {},
          )
        }
      })
    },
  },
  setup() {
    const isVisible = ref(false)

    const reviewData = ref({
      trades: '',
      status: '',
      note: '',
      pagsmileNotice: '',
    })

    const statusOptions = ref([
      { label: 'Pass', value: '2000' },
      { label: 'Processing', value: '1500' },
      { label: 'Return', value: '1100' },
    ])

    const noticeOptions = ref([
      { label: 'Duplicate Tracking Number', value: 'Duplicate Tracking Number' },
      { label: 'Dispatch Time before Order Time', value: 'Dispatch Time before Order Time' },
      { label: 'Returned to Sender', value: 'Returned to Sender' },
    ])

    function clearForm() {
      reviewData.value.trades = ''
      reviewData.value.status = ''
      reviewData.value.note = ''
      reviewData.value.pagsmileNotice = ''
      isVisible.value = false
    }

    return {
      statusOptions,
      reviewData,
      noticeOptions,
      isVisible,
      clearForm,
    }
  },
}
</script>

<style>

</style>
