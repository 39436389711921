<template>

  <div class="waybill-list">
    <!-- Filters -->
    <waybill-list-filters
      ref="waybillListFilters"
      @search="search"
      @download="download"
    />

    <!-- Table Container Card -->
    <b-card no-body>

      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="mb-md-0 mb-2"
          >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="selectAllRows"
            >
              <span class="text-nowrap">Select All</span>
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="clearSelected"
            >
              <span class="text-nowrap">Clear Selected</span>
            </b-button>
            <!-- v-b-toggle.sidebar-review -->
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="reviewSidebar"
            >
              <span class="text-nowrap">Review</span>
            </b-button>
            <b-button
              v-b-toggle.sidebar-batch-review
              variant="outline-primary"
            >
              <span class="text-nowrap">Batch Review</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 text-right"
          >
            <b-button
              variant="outline-primary"
              @click="trackingUpdate"
            >
              <span class="text-nowrap">Tracking Update</span>
            </b-button>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="waybills"
        :fields="waybillTableFields"
        responsive="sm"
        @row-selected="onRowSelected"
      >
        <template #cell(index)="data">
          <span>
            <b-form-checkbox
              v-model="selected"
              :value="data.item"
              class="custom-control-primary"
              :disabled="!$can('write', 'Waybill.List') && (data.item.review_status === 1000 || data.item.review_status === 1100 || data.item.review_status === 2000)"
              @change="waybillCheckboxChange(arguments[0], data.item, data.index)"
            />
          </span>
        </template>
        <template #cell(trade_no)="data">
          <b-link
            :to="{ name: 'transactions-detail', query:{trade_no: data.item.trade_no, app_id: data.item.app_id , out_trade_no: data.item.out_trade_no , zone_id: tzSelected} }"
            target="_blank"
            class="font-weight-bold d-block"
          >
            {{ data.item.trade_no }}
          </b-link>
        </template>

        <template #cell(trackingInfoFrom)="data">
          <div class="tracking-box" @mouseenter="trackingFromOver(data.item)">
            <div v-if="data.item.detail" class="tracking-detail" @mouseenter="trackingFromOver(data.item)">
              <p v-for="(item, index) in data.item.detail.from_detail" :key="index">
                {{ item }}
              </p>
            </div>
            <div v-if="data.item.detail" v-show="data.item[data.item.trade_no+'-from']" class="tracking-detail-info" @mouseleave="trackingFromDown(data.item)">
              <p v-for="(item, index) in data.item.detail.from_detail" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(trackingInfoTo)="data">
          <div class="tracking-box" @mouseenter="trackingToOver(data.item)">
            <div v-if="data.item.detail" class="tracking-detail" @mouseenter="trackingToOver(data.item)">
              <p v-for="(item, index) in data.item.detail.to_detail" :key="index">
                {{ item }}
              </p>
            </div>
            <div v-if="data.item.detail" v-show="data.item[data.item.trade_no+'-to']" class="tracking-detail-info" @mouseleave="trackingToDown(data.item)">
              <p v-for="(item, index) in data.item.detail.to_detail" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(review_status)="data">
          <span>
            {{ reviewStatusText(data.item.review_status) }}
          </span>
        </template>

        <template #cell(batch_status)="data">
          <span>
            {{ batchStatusText(data.item.batch_status) }}
          </span>
        </template>

        <template #cell(action)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            @click="showDetail(data.item)"
          >
            Detail
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalWaybills"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="columnFilterShow"
      title="Column Filter"
      ok-title="Accept"
      size="lg"
      centered
      @ok="filterColumnVisibility"
    >
      <draggable
        :list="tableFieldsTemplate"
        tag="ul"
        group="people"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(field, index) in tableFieldsTemplate"
          :key="index"
          tag="li"
        >
          <b-row>
            <b-col md="6">
              {{ field.label }}
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="field.isShow" class="ml-2">
                Show?
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="field.stickyColumn" class="ml-2">
                Sticky?
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-list-group-item>
      </draggable>
    </b-modal>

    <b-overlay
      :show="loading"
      no-wrap
    />

    <waybill-sidebar-review ref="waybillSidebarReview" @clearSidebarToTable="clearSidebarToTable" />
    <waybill-sidebar-batch-review />
    <waybill-sidebar-detail ref="waybillSidebarDetail" :waybill-detail="waybillDetail" :tz-selected="tzSelected" />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BOverlay, BButton, VBModal, BListGroupItem, BFormCheckbox, BTable, VBToggle, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import moment from 'moment-timezone'
import useJwt from '@/auth/jwt/useJwt'
import { showToast } from '@/libs/tool'
import ability from '@/libs/acl/ability'
import WaybillListFilters from './WaybillListFilters.vue'
import WaybillSidebarReview from './WaybillSidebarReview.vue'
import WaybillSidebarBatchReview from './WaybillSidebarBatchReview.vue'
import WaybillSidebarDetail from './WaybillSidebarDetail.vue'
import usewaybillJs from './waybill'

const {
  fetchWaybillList,
  trackingUpdating,
  downloadWaybill,
  fetchWaybillDetail,
} = usewaybillJs()

export default {
  components: {
    WaybillListFilters,
    draggable,

    BCard,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BButton,
    BListGroupItem,
    BFormCheckbox,
    BTable,
    BLink,
    WaybillSidebarReview,
    WaybillSidebarBatchReview,
    WaybillSidebarDetail,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      loading: false,
      searchRequest: {},
      waybills: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 100,
      tableFieldsTemplate: [
        {
          key: 'trade_no',
          label: 'TRANSACTION ID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'out_trade_no',
          label: 'MERCHANT REFERENCE',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'merchant',
          label: 'MERCHANT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'app',
          label: 'APP',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'create_time',
          label: 'ORDER TIME',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'order_amount',
          label: 'ORDER AMOUNT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'pay_amount',
          label: 'PAY AMOUNT',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'method',
          label: 'METHOD',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel',
          label: 'CHANNEL',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'trade_status',
          label: 'STATUS',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel_order_id',
          label: 'PARTNER ID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'channel_order_status',
          label: 'PARTNER STATUS',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'email',
          label: 'EMAIL',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'out_uid',
          label: 'MERCHANT UID',
          isShow: true,
          stickyColumn: false,
        },
        {
          key: 'update_time',
          label: 'UPDATE TIME',
          isShow: true,
          stickyColumn: false,
        },
      ],
      waybillTableFields: [
        { key: 'index', label: '' },
        { key: 'trade_no', label: 'TRANSACTION ID' },
        { key: 'merchant_name', label: 'Merchant' },
        { key: 'tracking_number', label: 'Tracking No' },
        { key: 'tracking_status', label: 'Tracking Status' },
        { key: 'trackingInfoFrom', label: 'Tracking Info (From)' },
        { key: 'trackingInfoTo', label: 'Tracking Info (To)' },
        { key: 'batch_status', label: 'Auto Group' },
        { key: 'review_status', label: 'Waybill Status' },
        { key: 'action', label: 'Action' },
      ],
      currentPage: 1,
      totalWaybills: 0,
      tzSelected: 'Etc/GMT+3',
      columnFilterShow: false,
      columnSeleted: [
        'trade_no',
        'out_trade_no',
        'merchant',
        'app',
        'create_time',
        'order_amount',
        'pay_amount',
        'method',
        'channel',
        'trade_status',
        'channel_order_id',
        'channel_order_status',
        'email',
        'out_uid',
        'update_time',
      ],
      // ------------------
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Date',
          field: 'startDate',
        },
        {
          label: 'Salary',
          field: 'salary',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      waybillDetail: {
        detail: {
          from: '',
          to: '',
          from_detail: '',
          to_detail: '',
        },
      },
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  created() {
  },
  methods: {
    resolveStatusVariant(status) {
      switch (status) {
        case 'Processing':
          return 'primary'
        case 'Success':
          return 'success'
        case 'ChargeBack':
          return 'danger'
        case 'Refunded':
        case 'PartialRefund':
          return 'danger'
        case 'RiskControl':
        case 'Dispute':
          return 'warning'
        case 'Refused':
          return 'info'
        default:
          return 'secondary'
      }
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    search(params) {
      this.loading = true
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      const that = this
      fetchWaybillList(
        that.searchRequest,
        transactions => {
          transactions.list.forEach(value => {
            // eslint-disable-next-line no-param-reassign
            value[`${value.trade_no}-from`] = false
            // eslint-disable-next-line no-param-reassign
            value[`${value.trade_no}-to`] = false
          })
          that.waybills = transactions.list
          that.totalWaybills = transactions.total
          that.dataMeta.from = that.currentPage * this.perPage - (this.perPage - 1)
          that.dataMeta.to = that.totalWaybills - that.currentPage * this.perPage > 0 ? that.currentPage * this.perPage : that.totalWaybills
          that.dataMeta.of = that.totalWaybills

          that.loading = false
        },
        () => {
          that.loading = false
        },
      )
    },
    download(data) {
      // this.searchRequest = params
      // fetchTransactionDetail(this, this.searchRequest)
      const params = {
        access_token: useJwt.getToken(),
        data,
      }
      const that = this
      that.loading = true
      downloadWaybill(
        params,
        msg => {
          that.loading = false
          showToast(this, 'success', msg)
          setTimeout(() => {
            that.$router.push({ name: 'waybill-download' })
          }, 2000)
        },
        () => {},
      )
    },
    filterColumnVisibility() {
      const tableFields = []
      this.tableFieldsTemplate.forEach(field => {
        if (field.isShow === true) {
          tableFields.push(field)
        }
      })
      this.waybillTableFields = tableFields
      localStorage.setItem('TransactionTableFields', JSON.stringify(this.waybillTableFields))
    },
    trackingUpdate() {
      const resultValue = this.selected.reduce((result, currentValue) => `${result},${currentValue.trade_no}`, '').slice(1)
      const params = {
        access_token: useJwt.getToken(),
        trades: resultValue,
      }
      if (params.trades.length === 0) {
        showToast(this, 'Warning', 'Please select one at least')
        return
      }
      const that = this
      that.loading = true
      trackingUpdating(
        params,
        () => {
          that.loading = false
          showToast(this, 'success', 'success')
          that.$refs.waybillListFilters.doSearch()
          that.$refs.selectableTable.clearSelected()
        },
        fail => {
          showToast(this, 'Warning', `tracking update failed with ${fail}`)
        },
      )
    },
    onRowSelected(items) {
      // 筛选出当前的选中项中return和pass的数据，不可选中
      // case 2000: return 'Pass' case 1100: return 'Return'
      const { selectableTable } = this.$refs
      const unSelected = items.filter(value => (value.review_status === 2000 || value.review_status === 1100 || value.review_status === 1000))
      if (!ability.can('write', 'Waybill.List') && unSelected && unSelected.length > 0) {
        unSelected.forEach(value => {
          selectableTable.localItems.forEach((item, index) => {
            if (item.trade_no === value.trade_no) {
              selectableTable.unselectRow(index)
            }
          })
        })
      }
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    reviewStatusText(value) {
      if (!value) return ''
      switch (value) {
        case 1000:
          return 'No Uploaded'
        case 1200:
          return 'Uploaded'
        case 2000:
          return 'Pass'
        case 1500:
          return 'Processing'
        case 1100:
          return 'Return'
        case 1300:
          return 'Unseen'
        default:
          return ''
      }
    },
    batchStatusText(value) {
      if (!value) return ''
      switch (value) {
        case 1400:
          return 'Pass'
        case 1401:
          return 'Processing'
        case 1402:
          return 'Return'
        default:
          return ''
      }
    },
    reviewSidebar() {
      // 如果为空的时候隐藏
      if (this.selected.length === 0) {
        showToast(this, 'Warning', 'Please select one at least')
        this.$refs.waybillSidebarReview.isVisible = false
        return
      }
      const resultValue = this.selected.reduce((result, currentValue) => `${result},\n${currentValue.trade_no}`, '').slice(2)
      this.$refs.waybillSidebarReview.reviewData.trades = resultValue
      this.$refs.waybillSidebarReview.isVisible = true
      this.$refs.waybillSidebarReview.$refs.reviewRules.reset()
    },
    clearSidebarToTable() {
      this.$refs.waybillListFilters.doSearch()
      this.$refs.selectableTable.clearSelected()
    },
    showDetail(detailData) {
      this.$refs.waybillSidebarDetail.isVisible = true
      const params = {
        access_token: useJwt.getToken(),
        trade_no: detailData.trade_no,
      }
      fetchWaybillDetail(
        data => {
          // 处理data
          let fromDetail = ''
          let toDetail = ''
          if (data.detail) {
            data.detail.from_detail.forEach((value, ind) => {
              if (ind + 1 === data.detail.from_detail.length) {
                fromDetail += `${value}`
              } else {
                fromDetail += `${value}\n\n`
              }
            })
            data.detail.to_detail.forEach((value, ind) => {
              if (ind + 1 === data.detail.to_detail.length) {
                toDetail += `${value}`
              } else {
                toDetail += `${value}\n\n`
              }
            })
          } else {
            // eslint-disable-next-line no-param-reassign
            data.detail = {}
            // eslint-disable-next-line no-param-reassign
            data.detail.from = ''
            // eslint-disable-next-line no-param-reassign
            data.detail.to = ''
            // eslint-disable-next-line no-param-reassign
            data.detail.from_detail = []
            // eslint-disable-next-line no-param-reassign
            data.detail.to_detail = []
          }

          this.waybillDetail = data
          this.waybillDetail.review_status = this.reviewStatusText(data.review_status)
          this.waybillDetail.batch_status = this.batchStatusText(data.batch_status)
          this.waybillDetail.detail.from_detail = fromDetail
          this.waybillDetail.detail.to_detail = toDetail
        },
        fail => {
          showToast(this, 'Warning', `waybill detail failed with ${fail}`)
        },
        params,
      )
    },
    trackingFromOver(item) {
      // eslint-disable-next-line no-param-reassign
      item[`${item.trade_no}-from`] = true
    },
    trackingFromDown(item) {
      // eslint-disable-next-line no-param-reassign
      item[`${item.trade_no}-from`] = false
    },
    trackingToOver(item) {
      // eslint-disable-next-line no-param-reassign
      item[`${item.trade_no}-to`] = true
    },
    trackingToDown(item) {
      // eslint-disable-next-line no-param-reassign
      item[`${item.trade_no}-to`] = false
    },
    waybillCheckboxChange(items, item, index) {
      const isExist = items.some(value => item.trade_no === value.trade_no)
      if (isExist) {
        this.$refs.selectableTable.selectRow(index)
      } else {
        this.$refs.selectableTable.unselectRow(index)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.waybill-list {
  .mx-datepicker-sidebar {
    width: 110px;
  }
  .mx-datepicker-sidebar+.mx-datepicker-content {
    margin-left: 110px;
  }
  .tracking-box {
    position: relative;
  }
  .tracking-detail {
    height: auto;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .tracking-detail-info {
    position: absolute;
    background: #fff;
    border: 1px solid;
    padding: 10px;
    width: 510px;
    height: 300px;
    overflow-y: scroll;
    top: -250px;
    z-index: 99;
  }
  .table-responsive-sm {
    overflow-x: scroll;
  }
  .b-table-row-selected.table-active td {
    background-color: #CFCFCF!important;
    color: #000000;
    font-weight: 500;
  }
}
</style>
