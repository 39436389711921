var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"bSidebar",attrs:{"id":"sidebar-review","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":"","visible":_vm.isVisible},on:{"change":function (val) { return _vm.$emit('update:is-task-handler-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Waybill Review ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"id":"hideSideBar","icon":"XIcon","size":"16"},on:{"click":function($event){hide();_vm.isVisible=false}}})],1),_c('validation-observer',{ref:"reviewRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Transaction ID","label-for":"transactionID"}},[_c('b-form-textarea',{attrs:{"id":"transactionID","rows":"6","trim":"","wrap":"hard","disabled":true},model:{value:(_vm.reviewData.trades),callback:function ($$v) {_vm.$set(_vm.reviewData, "trades", $$v)},expression:"reviewData.trades"}})],1),_c('validation-provider',{attrs:{"name":"Review Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Review Status","label-for":"reviewStatus"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"reviewStatus","placeholder":"select","options":_vm.statusOptions,"clearable":false,"reduce":function (val) { return val.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.reviewData.status),callback:function ($$v) {_vm.$set(_vm.reviewData, "status", $$v)},expression:"reviewData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Internal Notes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Internal Notes","label-for":"internalNotes"}},[_c('b-form-textarea',{attrs:{"id":"internalNotes","rows":"2","trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.reviewData.note),callback:function ($$v) {_vm.$set(_vm.reviewData, "note", $$v)},expression:"reviewData.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.reviewData.status === '1100')?_c('validation-provider',{attrs:{"name":"Notice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Notice","label-for":"notice"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"notice","placeholder":"select","options":_vm.noticeOptions,"clearable":false,"reduce":function (val) { return val.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.reviewData.pagsmileNotice),callback:function ($$v) {_vm.$set(_vm.reviewData, "pagsmileNotice", $$v)},expression:"reviewData.pagsmileNotice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('el-divider',{staticClass:"mt-3 mb-1"}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.reviewSubmit(hide)}}},[_vm._v(" Submit ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }