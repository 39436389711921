import { render, staticRenderFns } from "./WaybillSidebarDetail.vue?vue&type=template&id=73a00d50&"
import script from "./WaybillSidebarDetail.vue?vue&type=script&lang=js&"
export * from "./WaybillSidebarDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports