<template>
  <b-sidebar
    id="sidebar-detail"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :visible="isVisible"
    @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    @hidden="clearForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Waybill Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <fieldset :disabled="true">
        <!-- Body -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <!-- Transaction ID -->
          <b-form-group
            label="Transaction ID"
            label-for="transactionID"
          >
            <b-form-input
              id="transactionID"
              v-model="waybillDetail.trade_no"
              trim
              type="email"
            />
          </b-form-group>

          <!-- Merchant Reference -->
          <b-form-group
            label="Merchant Reference"
            label-for="merchantReference"
          >
            <b-form-input
              id="merchantReference"
              v-model="waybillDetail.out_trade_no"
              trim
              type="email"
            />
          </b-form-group>

          <!-- Merchant -->
          <b-form-group
            label="Merchant"
            label-for="merchant"
          >
            <b-form-textarea
              id="merchant"
              v-model="waybillDetail.merchant_name"
              rows="2"
              trim
            />
          </b-form-group>

          <!-- Create Time -->
          <b-form-group
            label="Create Time"
            label-for="createTime"
          >
            <b-form-input
              id="createTime"
              v-model="createTime"
              trim
            />
          </b-form-group>
          <!-- Tracking Submit Time -->
          <b-form-group
            label="Tracking Submit Time"
            label-for="trackingSubmitTime"
          >
            <b-form-input
              id="trackingSubmitTime"
              v-model="createdAtTime"
              trim
            />
          </b-form-group>
          <!-- Tracking No -->
          <b-form-group
            label="Tracking No"
            label-for="trackingNo"
          >
            <b-form-input
              id="trackingNo"
              v-model="waybillDetail.tracking_number"
              trim
            />
          </b-form-group>
          <!-- Carrier -->
          <b-form-group
            label="Carrier"
            label-for="carrier"
          >
            <b-form-input
              id="carrier"
              v-model="waybillDetail.carrier_code"
              trim
            />
          </b-form-group>
          <!-- Tracking Website -->
          <b-form-group
            label="Tracking Website"
            label-for="trackingWebsite"
          >
            <b-form-input
              id="trackingWebsite"
              v-model="waybillDetail.tracking_website"
              trim
            />
          </b-form-group>
          <!-- Tracking Status -->
          <b-form-group
            label="Tracking Status"
            label-for="trackingStatus"
          >
            <b-form-input
              id="trackingStatus"
              v-model="waybillDetail.tracking_status"
              trim
            />
          </b-form-group>
          <!-- Origin Country -->
          <b-form-group
            label="Origin Country"
            label-for="originCountry"
          >
            <b-form-input
              id="originCountry"
              v-model="waybillDetail.detail.from"
              trim
            />
          </b-form-group>
          <!-- Destination Country -->
          <b-form-group
            label="Destination Country"
            label-for="destinationCountry"
          >
            <b-form-input
              id="destinationCountry"
              v-model="waybillDetail.detail.to"
              trim
            />
          </b-form-group>
          <!-- Order Country -->
          <b-form-group
            label="Order Country"
            label-for="orderCountry"
          >
            <b-form-input
              id="orderCountry"
              v-model="waybillDetail.app_region"
              trim
            />
          </b-form-group>
          <!-- Tracking Info (From) -->
          <b-form-group
            label="Tracking Info (From)"
            label-for="fromDetail"
          >
            <b-form-textarea
              id="fromDetail"
              v-model="waybillDetail.detail.from_detail"
              :rows="waybillDetail.detail.from_detail?12:1"
              trim
              wrap="hard"
            />
          </b-form-group>
          <!-- Tracking Info (To) -->
          <b-form-group
            label="Tracking Info (To)"
            label-for="toDetail"
          >
            <b-form-textarea
              id="toDetail"
              v-model="waybillDetail.detail.to_detail"
              :rows="waybillDetail.detail.to_detail?12:1"
              trim
              wrap="hard"
            />
          </b-form-group>
          <!-- Merchant Notes -->
          <b-form-group
            label="Merchant Notes"
            label-for="merchantNote"
          >
            <b-form-textarea
              id="merchantNote"
              v-model="waybillDetail.merchant_note"
              :rows="waybillDetail.merchant_note?2:1"
              trim
              wrap="hard"
            />
          </b-form-group>
          <!-- Auto Group -->
          <b-form-group
            label="Auto Group"
            label-for="autoGroup"
          >
            <b-form-input
              id="autoGroup"
              v-model="waybillDetail.batch_status"
              trim
            />
          </b-form-group>

          <!-- Review Status -->
          <b-form-group
            label="Review Status"
            label-for="reviewStatus"
          >
            <b-form-input
              id="reviewStatus"
              v-model="waybillDetail.review_status"
              trim
            />
          </b-form-group>
          <!-- Internal Notes -->
          <b-form-group
            label="Internal Notes"
            label-for="internalNotes"
          >
            <b-form-textarea
              id="internalNotes"
              v-model="waybillDetail.note"
              :rows="waybillDetail.note?2:1"
              trim
              wrap="hard"
            />
          </b-form-group>
          <!-- Notice -->
          <b-form-group
            label="Notice"
            label-for="notice"
          >
            <b-form-textarea
              id="notice"
              v-model="waybillDetail.pagsmile_notice"
              :rows="waybillDetail.pagsmile_notice?2:1"
              trim
              wrap="hard"
            />
          </b-form-group>

        </b-form>
      </fieldset>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    waybillDetail: {
      type: Object,
      default: () => {},
    },
    tzSelected: {
      type: String,
      default: '',
    },
  },
  computed: {
    createTime() {
      return this.timeZoneFilter(this.waybillDetail.create_time)
    },
    createdAtTime() {
      return this.timeZoneFilter(this.waybillDetail.detail.created_at)
    },
  },
  methods: {
    timeZoneFilter(dt) {
      if (!dt) {
        return ''
      }
      return moment.tz(moment.tz(dt, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  setup() {
    const isVisible = ref(false)

    function clearForm() {
      isVisible.value = false
    }

    return {
      isVisible,

      clearForm,
    }
  },
}
</script>

<style>

</style>
