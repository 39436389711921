<template>
  <b-sidebar
    id="sidebar-batch-review"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Waybill Review
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide();waybillFile=null"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Upload Waybill File -->
        <b-form-group
          label="Upload Waybill File"
          label-for="uploadWaybillFile"
        >
          <b-form-file
            id="uploadWaybillFile"
            v-model="waybillFile"
            placeholder="Choose a file..."
          >
            <template slot="file-name"
                      slot-scope="{ names }"
            >
              <b-badge variant="primary">
                {{ names[0] }}
              </b-badge>
            </template>
          </b-form-file>
        </b-form-group>

        <!-- Download Template -->
        <b-form-group
          label="Download Template"
          label-for="downloadTemplate"
        >
          <b-link href="https://res.pagsmile.com/lib/template/WAYBILL_UPLOAD_TEMPLATE_ADMIN.xlsx" @click="downloadTemplate">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
            >
              Template
            </b-button>
          </b-link>
        </b-form-group>

        <el-divider class="mt-3 mb-1" />

        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="batchReview(hide)"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton, BBadge, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { showToast } from '@/libs/tool'
import usewaybillJs from './waybill'

const {
  batchReviewSubmitDetail,
  // batchReviewDownloadTemplate,
} = usewaybillJs()

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormFile, BButton, BBadge, BLink,
  },
  directives: {
    Ripple,
  },
  setup() {
    const waybillFile = ref()

    function batchReview(hide) {
      if (!waybillFile.value || waybillFile.value.length <= 0) {
        showToast(this, 'Warning', 'No File Select')
        return
      }
      if (waybillFile.value.name.indexOf('.xlsx') === -1) {
        showToast(this, 'Warning', 'The file is not in the correct format')
        return
      }
      const files = new FormData()
      files.append('files', waybillFile.value)
      const params = {
        access_token: useJwt.getToken(),
        files,
      }
      batchReviewSubmitDetail(
        params,
        () => {
          showToast(this, 'success', 'success')
          waybillFile.value = null
          hide()
        },
        fail => {
          showToast(this, 'Warning', fail)
        },
      )
    }

    function downloadTemplate() {
      // const params = {
      //   access_token: useJwt.getToken(),
      // }
      // batchReviewDownloadTemplate(
      //   data => {
      //     const blob = new Blob([data]) // application/x-xls , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      //     const link = document.createElement('a')
      //     link.href = window.URL.createObjectURL(blob)
      //     link.download = 'WAYBILL_UPLOAD_TEMPLATE_ADMIN.xlsx'
      //     link.click()
      //   },
      //   fail => {
      //     showToast(this, 'Warning', fail)
      //   },
      //   params,
      // )
    }

    return {
      batchReview,
      waybillFile,
      downloadTemplate,
    }
  },
}
</script>

<style>

</style>
