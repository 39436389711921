<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Review Level</label>
          <v-select
            v-model="waybillBean.appType"
            :options="appTypeOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="appTypeChange"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="waybillBean.merchant_no"
            :options="merchantOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="merchantNoChange"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>App</label>
          <v-select
            v-model="waybillBean.app_id"
            placeholder="App"
            :options="appOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Mode</label>
          <v-select
            v-model="waybillBean.date_mode"
            :options="dateModeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Timezone</label>
          <v-select
            v-model="waybillBean.zone_id"
            :options="tzOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="waybillBean.dateRange"
            placeholder="Date Range"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
            :show-time-panel="showTimeRangePanel"
            @close="handleRangeClose"
          >
            <template v-slot:footer>
              <b-button variant="flat-primary" @click="toggleTimeRangePanel">
                {{ showTimeRangePanel ? 'select date' : 'select time' }}
              </b-button>
            </template>
          </date-picker>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Waybill Upload?</label>
          <v-select
            v-model="waybillBean.waybill_upload"
            :options="waybillUpload"
            :clearable="false"
            class="w-100"
            @input="waybillUploadChange"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Auto Group</label>
          <v-select
            v-model="waybillBean.batch_status"
            :options="autoGroupOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            :disabled="waybillBean.waybill_upload.value !== 'Uploaded'"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Review Status</label>
          <v-select
            v-model="waybillBean.review_status"
            :options="reviewStatusOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            :disabled="waybillBean.waybill_upload.value !== 'Uploaded'"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Carrier</label>
          <v-select
            v-model="waybillBean.carrier_code"
            :options="carrierOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tracking Status</label>
          <v-select
            v-model="waybillBean.tracking_status"
            placeholder="Tracking Status"
            :options="trackingStatusOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Search Mode</label>
          <v-select
            v-model="waybillBean.search_mode"
            placeholder="Search Mode"
            :options="searchModeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:searchModeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Search Key</label>
          <b-form-input
            id="searchKeyInput"
            v-model="waybillBean.search_key"
            placeholder="Key Word Here..."
            @keydown.enter.native="doSearch"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label />
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <b-button
            class="mt-2 ml-2"
            variant="primary"
            @click="doDownload"
          >
            Download
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- <b-overlay
      :show="loading"
      no-wrap
    /> -->
  </b-card>
</template>

<script>
import {
  BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { showAlert } from '@/libs/tool'
import useJwt from '@/auth/jwt/useJwt'
import usewaybillJs from './waybill'

const {
  fetchAppListAll,
  fetchCommonOptions,
} = usewaybillJs()

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    DatePicker,
  },
  props: {
  },
  data() {
    return {
      // params: {
      //   merchant_no: '',
      //   app_id: '',
      //   method: '',
      //   trade_status: '',
      //   start_time: '',
      //   end_time: '',
      //   channel: '',
      // },

      // payChannelList: [],

      // merchantFilter: '',
      // appFilter: '',
      // appOptions: [],

      // methodFilter: { type_id: '', type_name: '', type_desc: 'All' },
      // methodOptions: [],

      // channelFilter: { label: 'All', value: '' },
      // channelOptions: [],

      // statusFilter: '',
      // statusOptions: [
      //   { label: 'All', value: '' },
      //   { label: 'Initial', value: '10001' },
      //   { label: 'Processing', value: '18000' },
      //   { label: 'RiskControll', value: '27000' },
      //   { label: 'Cancelled', value: '25000' },
      //   { label: 'Success', value: '20000' },
      //   { label: 'Dispute', value: '30000' },
      //   { label: 'Refused', value: '21000' },
      //   { label: 'OpenRefund', value: '22005' },
      //   { label: 'RefundProcessing', value: '22006' },
      //   { label: 'Refunded', value: '22000' },
      //   { label: 'ChargeBack', value: '23000' },
      //   { label: 'Fail', value: '40000' },
      // ],

      // dateModeFilter: '1',

      // searchModeFilter: '1',
      // searchKey: '',
      showTimeRangePanel: false,
      // dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
      // 新增
      appTypeOptions: [
        { value: '', label: 'All' },
        { value: '1', label: 'All Pass' },
        { value: '3', label: 'Regular' },
        // { value: '3', label: 'General' },
      ],
      trackingStatusOptions: [
        { value: '', label: 'All' },
        { value: 'pending', label: 'pending' },
        { value: 'notfound', label: 'notfound' },
        { value: 'transit', label: 'transit' },
        { value: 'pickup', label: 'pickup' },
        { value: 'delivered', label: 'delivered' },
        { value: 'expired', label: 'expired' },
        { value: 'undelivered', label: 'undelivered' },
        { value: 'exception', label: 'exception' },
        { value: 'InfoReceived', label: 'InfoReceived' },
      ],
      dateModeOptions: [
        { label: 'Create Time', value: '1' },
        { label: 'Settlement Time', value: '2' },
        { label: 'Tracking Submit Time', value: '3' },
        { label: 'Review Time', value: '4' },
      ],
      tzOptions: [
        { label: 'America/Sao_Paulo', value: 'Etc/GMT+3' },
        { label: 'Etc/GMT', value: 'Etc/GMT' },
        { label: 'Asia/Shanghai', value: 'Etc/GMT-8' },
        { label: 'America/Mexico_City', value: 'Etc/GMT+6' },
        // { label: 'Etc/GMT-12', value: 'Etc/GMT-12' },
        // { label: 'Etc/GMT-11', value: 'Etc/GMT-11' },
        // { label: 'Etc/GMT-10', value: 'Etc/GMT-10' },
        // { label: 'Etc/GMT-9', value: 'Etc/GMT-9' },
        // { label: 'Etc/GMT-7', value: 'Etc/GMT-7' },
        // { label: 'Etc/GMT-6', value: 'Etc/GMT-6' },
        // { label: 'Etc/GMT-5', value: 'Etc/GMT-5' },
        // { label: 'Etc/GMT-4', value: 'Etc/GMT-4' },
        // { label: 'Etc/GMT-3', value: 'Etc/GMT-3' },
        // { label: 'Etc/GMT-2', value: 'Etc/GMT-2' },
        // { label: 'Etc/GMT-1', value: 'Etc/GMT-1' },
        // { label: 'Etc/GMT+1', value: 'Etc/GMT+1' },
        // { label: 'Etc/GMT+2', value: 'Etc/GMT+2' },
        // { label: 'Etc/GMT+4', value: 'Etc/GMT+4' },
        // { label: 'Etc/GMT+5', value: 'Etc/GMT+5' },
        // { label: 'Etc/GMT+7', value: 'Etc/GMT+7' },
        // { label: 'Etc/GMT+8', value: 'Etc/GMT+8' },
        // { label: 'Etc/GMT+9', value: 'Etc/GMT+9' },
        // { label: 'Etc/GMT+10', value: 'Etc/GMT+10' },
        // { label: 'Etc/GMT+11', value: 'Etc/GMT+11' },
        // { label: 'Etc/GMT+12', value: 'Etc/GMT+12' },
      ],
      carrierOptions: [
        { value: '', label: 'All' },
      ],
      searchModeOptions: [
        { label: 'Transaction ID', value: '1' },
        { label: 'Merchant Reference', value: '2' },
      ],
      waybillUpload: [
        { value: 'All', label: 'All' },
        { value: 'Uploaded', label: 'Uploaded' },
        { value: 'No Upload', label: 'No Upload' },
      ],
      // BATCH_PASS(1400, "Batch Pass") 系统通过
      // BATCH_PROCESSING(1401, "Batch Processing") 暂不通过
      // BATCH_FAIL(1402, "Batch Fail") 系统失败
      autoGroupOptions: [
        { value: '', label: 'All' },
        { value: '1400', label: 'Pass' },
        { value: '1401', label: 'Processing' },
        { value: '1402', label: 'Return' },
      ],
      reviewStatusOptions: [
        { value: 'All', label: 'All' },
        { value: 'Pass', label: 'Pass' },
        { value: 'Processing', label: 'Processing' },
        { value: 'Return', label: 'Return' },
        { value: 'Unseen', label: 'Unseen' },
      ],
      waybillBean: {
        appType: '',
        merchant_no: '',
        tracking_status: '',
        date_mode: '1',
        zone_id: 'Etc/GMT+3',
        dateRange: [],
        carrier_code: '',
        search_mode: '1',
        search_key: '',
        waybill_upload: 'All',
        batch_status: '',
        review_status: 'All',
        page_num: 1,
        page_size: 10,
        app_id: '',
      },
      merchantOptions: [ // 动态获取商户数据
        { value: '', label: 'All' },
      ],
      appOptions: [ // 动态获取App数据
        { value: '', label: 'All' },
      ],
      allAppList: [],
    }
  },
  computed: {
    channelDisabled() {
      return this.methodFilter === '' || this.methodFilter.type_id === ''
    },
  },
  watch: {
    // dateRange: function formatWithTz(object) {
    //   const startTime = object[0]
    //   const endTime = object[1]
    //   const startTzTime = moment.tz(startTime, this.waybillBean.zone_id)
    //   const endTzTime = moment.tz(endTime, this.waybillBean.zone_id)
    //   const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
    //   const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')

    //   const dateArray = new Array(0)
    //   dateArray.push(startTimeStr, endTimeStr)
    //   this.$emit('update:dateRange', dateArray)
    // },
    // merchantFilter: function fillApps(merchantNo) {
    //   this.appOptions = []
    //   this.appFilter = ''
    //   if (merchantNo === 'All') {
    //     this.appOptions = [{ label: 'All', value: 'All' }]
    //     this.appFilter = this.appOptions[0].value
    //   } else {
    //     // fetchMerchantApps(this, merchantNo, apps => {
    //     //   this.appOptions = apps
    //     //   this.appFilter = this.appOptions[0].value
    //     // })
    //   }
    // },
    // methodFilter: function resetChannel(method) {
    //   const defaultChannel = { label: 'All', value: '' }
    //   const channels = []
    //   channels.push(defaultChannel)

    //   this.payChannelList.forEach(item => {
    //     if (method === item.type_name) {
    //       const cl = {
    //         label: item.cname,
    //         value: item.cid,
    //       }
    //       channels.push(cl)
    //     }
    //   })
    //   this.channelOptions = channels
    //   this.channelFilter = ''
    // },
  },
  created() {
    // fetchTransactionDetail(this, merchants => {
    //   this.merchantOptions = merchants
    //   this.merchantFilter = this.merchantOptions[0].value
    // })
    // fetchChannelFilters(this, filters => {
    //   this.methodOptions = filters.methods
    //   this.methodFilter = ''
    // })
    // fetchPayChannelListDetail(this, list => {
    //   this.payChannelList = list
    // })

    this.waybillBean.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
  },
  mounted() {
    this.getCarrierData()
    this.getAppListAll()
    // this.doSearch()
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    genParams() {
      let [start, end] = this.waybillBean.dateRange

      start = moment.tz(moment.tz(start, this.waybillBean.zone_id), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      end = moment.tz(moment.tz(end, this.waybillBean.zone_id), 'UTC').format('YYYY-MM-DD HH:mm:ss')

      const params = {
        // appType: '',
        merchant_no: this.waybillBean.merchant_no,
        tracking_status: this.waybillBean.tracking_status,
        date_mode: this.waybillBean.date_mode,
        zone_id: encodeURIComponent(this.waybillBean.zone_id),
        start_time: start,
        end_time: end,
        carrier_code: this.waybillBean.carrier_code,
        search_mode: this.waybillBean.search_mode,
        search_key: this.waybillBean.search_key,
        batch_status: this.waybillBean.batch_status,
        page_num: 1,
        page_size: 100,
        app_id: this.waybillBean.app_id,
      }
      // No Uploaded -> 1000
      // Uploaded -> 1200
      // Uploaded & Review Status = Pass -> 2000
      // Uploaded & Review Status = Processing -> 1500
      // Uploaded & Review Status = Return -> 1100
      // Uploaded & Review Status = Unseen -> 1300
      if (this.waybillBean.waybill_upload.value === 'No Upload') {
        params.review_status = '1000'
      } else if (this.waybillBean.waybill_upload.value === 'Uploaded' && this.waybillBean.review_status === 'All') {
        params.review_status = '1200'
      } else if (this.waybillBean.waybill_upload.value === 'Uploaded' && this.waybillBean.review_status === 'Pass') {
        params.review_status = '2000'
      } else if (this.waybillBean.waybill_upload.value === 'Uploaded' && this.waybillBean.review_status === 'Processing') {
        params.review_status = '1500'
      } else if (this.waybillBean.waybill_upload.value === 'Uploaded' && this.waybillBean.review_status === 'Return') {
        params.review_status = '1100'
      } else if (this.waybillBean.waybill_upload.value === 'Uploaded' && this.waybillBean.review_status === 'Unseen') {
        params.review_status = '1300'
      }
      return params
    },
    doSearch() {
      const params = this.genParams()
      this.$emit('search', params)
    },
    doDownload() {
      const params = this.genParams()
      this.$emit('download', params)
    },
    waybillUploadChange(value) {
      if (value !== 'Uploaded') {
        this.waybillBean.batch_status = ''
        this.waybillBean.review_status = 'All'
      }
    },
    // 获取carrier数据
    getCarrierData() {
      fetchCommonOptions(
        options => {
          const { carriers } = options
          const defaultOptions = [{ label: 'All', value: '' }]
          this.carrierOptions = defaultOptions.concat(carriers.map(carrier => ({ label: carrier.code, value: carrier.code })))
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Fetch options fail with ${fail}`)
        },
      )
    },
    getAppListAll() {
      const that = this
      const params = {
        access_token: useJwt.getToken(),
      }
      // that.loading = true
      fetchAppListAll(
        appList => {
          this.allAppList = appList
          // that.loading = false
          that.setMerchantOptions('')
        },
        () => {
          // that.loading = false
        },
        params,
      )
    },
    setMerchantOptions(type) {
      const merchantOptions = [{ value: '', label: 'All' }]
      if (type === '') {
        this.merchantOptions = merchantOptions.concat(this.allAppList.map(value => ({ value: value.merchant_no, label: value.merchant_name })))
      } else {
        const appListByLevel = this.allAppList.filter(value => type === (`${value.review_level}`))
        this.merchantOptions = merchantOptions.concat(appListByLevel.map(value => ({ value: value.merchant_no, label: value.merchant_name })))
      }

      const bean = {}
      this.merchantOptions = this.merchantOptions.reduce((item, next) => {
        // eslint-disable-next-line no-unused-expressions
        bean[next.value] ? '' : bean[next.value] = true && item.push(next)
        return item
      }, [])
      this.waybillBean.merchant_no = ''
      let currentMerchantNo = ''
      this.merchantOptions.forEach(value => {
        if (value.value) {
          currentMerchantNo = `${currentMerchantNo},${value.value}`
        }
      })
      if (type === '') {
        this.setAppOption('')
      } else {
        this.setAppOption(currentMerchantNo)
      }
    },
    setAppOption(merchantNo) {
      const appOptions = [{ value: '', label: 'All' }]
      if (merchantNo === '') {
        this.appOptions = appOptions.concat(this.allAppList.map(value => ({ value: value.app_id, label: value.app_name })))
      } else {
        const filterAppList = this.allAppList.filter(value => merchantNo.indexOf(value.merchant_no) > -1) // 筛选符合商户的APP数据
        this.appOptions = appOptions.concat(filterAppList.map(value => ({ value: value.app_id, label: value.app_name }))) // 组装数据
      }
      this.waybillBean.app_id = ''
    },
    // 变更apptype
    appTypeChange(value) {
      this.setMerchantOptions(value)
    },
    merchantNoChange(value) {
      this.setAppOption(value)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
